<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="700px">
      <v-card id="borderradius">
        <!-- <v-card-title v-if="filedata.URL_payment"
          >{{ $t("admintoolbar.paymentlist") }}<v-spacer></v-spacer>
          <v-icon size="35">mdi-credit-card-outline</v-icon>
        </v-card-title>
        <v-card-title v-else
          >{{ $t("packagenew.package_history") }}<v-spacer></v-spacer>
          <v-icon size="35">mdi-clipboard-list-outline</v-icon>
        </v-card-title>
        <v-divider></v-divider> -->
        <v-card-text class="pa-5">
          <v-layout :style="'color:' + color.theme">
            <v-flex d-flex justify-center>
              <h3 v-if="filedata.URL_payment">{{ $t("admintoolbar.paymentlist") }}</h3>
              <h3 v-else>{{ $t("packagenew.package_history") }}</h3>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon :color="color.theme" @click="$emit('closedialog')">close</v-icon>
            </v-flex>
          </v-layout>
          <div>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs5 lg3 class="pl-4 pt-4">
                <p>{{ $t("packagepurchasehistory.package_name") }}<span class="float-right">:</span></p>
              </v-flex>
              <v-flex xs7 lg9 class="pl-4 pt-4">
                <p class="font-weight-bold">
                  {{
                    filedata.package_name !== ""
                      ? filedata.package_name +
                        " | " +
                        filedata.package_storage_main
                      : "-"
                  }}
                </p>
              </v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs5 lg3 class="pl-4">
                <p>{{ $t("packagepurchasehistory.package_name_addon") }}<span class="float-right">:</span></p>
              </v-flex>
              <v-flex xs7 lg9 class="pl-4">
                <p class="font-weight-bold">{{ filedata.package_name_addon }}</p>
              </v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs5 lg3 class="pl-4">
                <p>{{ $t("packagepurchasehistory.price") }}<span class="float-right">:</span></p>
              </v-flex>
              <v-flex xs7 lg9 class="pl-4">
                <p class="font-weight-bold">
                  {{ numberWithCommas(filedata.amount) }}
                  {{ $t("my_package.baht") }}
                </p>
              </v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs5 lg3 class="pl-4">
                <p v-if="!filedata.URL_payment">
                  {{ $t("packagepurchasehistory.payment_type") }}<span class="float-right">:</span>
                </p>
              </v-flex>
              <v-flex xs7 lg9 class="pl-4" v-if="!filedata.URL_payment">
                <p class="font-weight-bold">{{ filedata.pay_type || "-" }}</p>
              </v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap v-if="filedata.URL_payment">
              <v-flex xs5 lg3 class="pl-4">
                <p>{{ $t("paymentlist.payment_date") }}<span class="float-right">:</span></p>
              </v-flex>
              <v-flex xs7 lg9 class="pl-4">
                <p class="font-weight-bold">{{ formatdatetime(filedata.paid_dtm) }}</p>
              </v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs5 lg3 class="pl-4">
                <p>{{ $t("packagepurchasehistory.dateofpayment") }}<span class="float-right">:</span></p>
              </v-flex>
              <v-flex xs7 lg9 class="pl-4">
                <p class="font-weight-bold">{{ formatdatetime(filedata.paid_dtm) }}</p>
              </v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs5 lg3 class="pl-4">
                <p>{{ $t("packagepurchasehistory.payment_status") }}<span class="float-right">:</span></p>
              </v-flex>
              <v-flex xs7 lg9 class="pl-4">
                <p class="font-weight-bold">
                  <v-chip
                    :color="
                      filedata.status_payment === 'wait' ? $vuetify.theme.dark  ? 'amber--text amber'  : 'amber--text amber lighten-5'
                      : filedata.status_payment === 'Success' ? $vuetify.theme.dark  ? 'green--text green'  : 'green--text green lighten-5'
                      : $vuetify.theme.dark  ? 'red--text red' : 'red--text red lighten-5'
                    "
                    small
                  >
                    {{ filedata.status_payment === "Incomplete" ? "Fail" : filedata.status_payment }}
                  </v-chip>
                </p>
              </v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs5 lg3 class="pl-4">
                <p>{{ $t("packagepurchasehistory.tax_invoice") }}<span class="float-right">:</span></p>
              </v-flex>
              <v-flex xs7 lg9 class="pl-4">
                <p>
                  <v-btn
                    class="elevation-0 pa-0 font-weight-bold"
                    :disabled="
                      filedata.status_payment !== 'Success' ||
                      filedata.file_id === ''
                    "
                    @click="$emit('downloadtaxinvoice')"
                    color="#1876D1"
                    text 
                    small
                    style="text-decoration: underline;"
                  >
                    <span>{{ $t('dowloads')}}</span>
                  </v-btn>
                </p>
              </v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap v-if="filedata.URL_payment">
              <v-flex xs5 lg3 class="pl-4">
                <p>{{ $t("paymentlist.pay") }}<span class="float-right">:</span></p>
              </v-flex>
              <v-flex xs7 lg9 class="pl-4">
                <p>
                  <v-btn
                    class="elevation-0 white--text font-weight-bold"
                    :disabled="
                      filedata.status_payment === 'Success' ||
                      filedata.status_payment === 'Cancel' ||
                      filedata.status_payment === 'success_nexmount'
                    "
                    @click="$emit('payment')"
                    :color="color.theme"
                    small
                  >
                    {{ $t("paymentlist.btnpay") }}
                  </v-btn>
                </p>
              </v-flex>
            </v-layout>
          </div>
        </v-card-text>
        <!-- <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$emit('closedialog')" color="red" dark>{{
            $t("resendfile.closefromdateresend")
          }}</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  props: ["show", "filedata"],
  data: function() {
    return {};
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "storage_usage",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          // console.log('fileresend',this.fileresend);
        }
        return this.show;
      },
    },
  },
  methods: {
    numberWithCommas(x) {
      if (!x || x === null || x === undefined) {
        return "-";
      } else {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    formatdatetime(_datetime) {
      if (
        _datetime === "" ||
        _datetime === "-" ||
        _datetime === undefined ||
        _datetime === null
      ) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },
  },
};
</script>
